// import "photoswipe/dist/photoswipe.css";
// import "photoswipe/dist/default-skin/default-skin.css";

import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
// import { CustomGallery, Item, DefaultLayout } from "react-photoswipe-gallery";
// import PhotoswipeUIDefault from "photoswipe/dist/photoswipe-ui-default";
// import Slider from "react-slick";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";

import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";

import locales from "../../../constants";

const Memtech = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "en";

  // const layoutGalleryRef = useRef();

  return (
    <Layout
      seo={{
        title: "Memtech",
        href: slug,
        lang: "en",
        ogImage: require("../../../assets/img/portfolio/memtech_og_image.jpg"),
      }}
      header={{
        background:
          "linear-gradient( 45deg, #e252d8, #ca41d6, #df5597, #e72e7c, #d4266f )",
        icons: "#e72e7c",
        navClass: "memtech__nav",
        ogImage: require("../../../assets/img/portfolio/memtech_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/pl/projekty/memtech/",
      }}
    >
      <PortfolioHeader name="memtech" />
      <div className="overflow-hidden">
        <section
          className="container-fluid portfolio-intro memtech-intro memtech-section memtech-section__intro"
          id="info"
        >
          <div className="row">
            <div className="col-md-6">
              <div className="inner">
                <h1>Memtech</h1>
                <ul>
                  <li>Logo</li>
                  <li>Online store</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="inner inner--divider">
                <p>
                  We have carried out a full rebranding of the Memtech brand,
                  designing a coherent visual identification and developing a
                  modern and fast online store, based on the React.js
                  technology.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid memtech-section memtech-section__logo">
          <div className="row align-items-center">
            <div className="col-12 col-lg-5 text-center">
              <div className="colors">
                <div className="colors__primary float-left"></div>
                <div className="colors__secondary float-left clearfix"></div>
              </div>
              <div className="img_wrapper offset-lg-5">
                <picture>
                  <source
                    src={require("../../../assets/img/portfolio/memtech_logo.avif")}
                    type="image/avif"
                    alt="Memtech logo"
                  />
                  <img
                    className="img-fluid logo"
                    src={require("../../../assets/img/portfolio/memtech_logo.jpg")}
                    alt="Memtech logo"
                  />
                </picture>
              </div>
            </div>
            <div className="col-12 col-lg-5 offset-lg-2 col_2">
              <h3>Logo</h3>
              <div className="row">
                <TrackVisibility once offset={300} className="col-sm-6">
                  {(isVisible) => (
                    <>
                      <p>Magenta CMYK:</p>
                      <h5>
                        <span>0</span>
                        <CountUp
                          start={0}
                          duration={10}
                          end={isVisible ? 90 : 0}
                        />
                        <CountUp
                          start={0}
                          duration={10}
                          end={isVisible ? 15 : 0}
                        />
                        <span>0</span>
                      </h5>
                    </>
                  )}
                </TrackVisibility>
                <TrackVisibility once offset={300} className="col-sm-6">
                  {(isVisible) => (
                    <>
                      <p>Purple CMYK:</p>
                      <h5>
                        <CountUp
                          start={0}
                          duration={10}
                          end={isVisible ? 89 : 0}
                        />
                        <CountUp
                          start={0}
                          duration={10}
                          end={isVisible ? 100 : 0}
                        />
                        <CountUp
                          start={0}
                          duration={10}
                          end={isVisible ? 4 : 0}
                        />
                        <CountUp
                          start={0}
                          duration={10}
                          end={isVisible ? 20 : 0}
                        />
                      </h5>
                    </>
                  )}
                </TrackVisibility>
                <TrackVisibility once offset={300} className="col-sm-6">
                  {(isVisible) => (
                    <>
                      <p>RGB:</p>
                      <h5>
                        <CountUp
                          start={0}
                          duration={10}
                          end={isVisible ? 231 : 0}
                        />
                        <CountUp
                          start={0}
                          duration={10}
                          end={isVisible ? 46 : 0}
                        />
                        <CountUp
                          start={0}
                          duration={10}
                          end={isVisible ? 124 : 0}
                        />
                      </h5>
                    </>
                  )}
                </TrackVisibility>
                <TrackVisibility once offset={300} className="col-sm-6">
                  {(isVisible) => (
                    <>
                      <p>RGB:</p>
                      <h5>
                        <CountUp
                          start={0}
                          duration={10}
                          end={isVisible ? 62 : 0}
                        />
                        <CountUp
                          start={0}
                          duration={10}
                          end={isVisible ? 28 : 0}
                        />
                        <CountUp
                          start={0}
                          duration={10}
                          end={isVisible ? 109 : 0}
                        />
                      </h5>
                    </>
                  )}
                </TrackVisibility>

                <div className="col-sm-6">
                  <p>HEX:</p>
                  <h5>e72e7c</h5>
                </div>
                <div className="col-sm-6">
                  <p>HEX:</p>
                  <h5>3e1c6d</h5>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="memtech-section memtech-section__ecomerce">
          <div className="container-fluid">
            <div className="memtech-section__laptop laptop">
              <div className="slider-wrapper ">
                <div className="laptop__content">
                  <ReactCompareSlider
                    itemOne={
                      <ReactCompareSliderImage
                        src={require("../../../assets/img/portfolio/memtech_page_new.jpg")}
                        alt="Nowa Strona Memtech"
                      />
                    }
                    itemTwo={
                      <ReactCompareSliderImage
                        src={require("../../../assets/img/portfolio/memtech_page_old.jpg")}
                        alt="Stara strona Memtech"
                      />
                    }
                  />
                </div>
              </div>
              <img
                className="img-fluid laptop__frame"
                src={require("../../../assets/img/portfolio/memtech_laptop.png")}
                alt="Szary laptop"
              />
              <img
                className="img-fluid laptop__shadow"
                src={require("../../../assets/img/portfolio/memtech_shadow.png")}
                alt="Cień laptopa"
              />
            </div>

            <div className="row memtech-section__screens">
              <div className="col-12 col-lg-6">
                <h2>Online store</h2>
                <p>
                  We created a mock-up and design of a new Memtech online store.
                </p>
                <p>
                  The store has been coded as JamStack, based on the React.js
                  technology, thanks to which each page is loaded to potential
                  customers in fractions of a second, regardless of the number
                  of products available on the store.
                </p>
              </div>
              <div className="col-12 col-lg-6">
                <img
                  src={require("../../../assets/img/portfolio/memtech_page_news.jpg")}
                  alt="Memtech screen strony nowości"
                />
              </div>
              <div className="col-12 col-lg-6">
                <img
                  src={require("../../../assets/img/portfolio/memtech_page_cart.jpg")}
                  alt="Memtech screen strony koszyk"
                />
              </div>
              <div className="col-12 col-lg-6">
                <img
                  src={require("../../../assets/img/portfolio/memtech_page_payment.jpg")}
                  alt="Memtech screen strony płatność"
                />
              </div>
              <div className="col-12 col-lg-6">
                <img
                  src={require("../../../assets/img/portfolio/memtech_iphone.png")}
                  alt="Memtech na smartphonie"
                />
              </div>
              <div className="col-12 col-lg-6">
                <img
                  src={require("../../../assets/img/portfolio/memtech_page_filter.jpg")}
                  alt="Memtech screen strony filtr"
                />
              </div>
            </div>
          </div>
        </section>

        {/* <section className="memtech-section memtech-section__gallery">
          <div className="container-fluid pt-0 ">
            <div className="memtech-gallery d-none d-lg-block">
              <CustomGallery
                layoutRef={layoutGalleryRef}
                ui={PhotoswipeUIDefault}
              >
                <div className="row">
                  <div className="col-4">
                    <Item
                      original={require("../../../assets/img/portfolio/memtech_page_contact.png")}
                      thumbnail={require("../../../assets/img/portfolio/memtech_page_contact_thumbnail.png")}
                      width="1920"
                      height="1947"
                    >
                      {({ ref, open }) => (
                        <div className="memtech-gallery__image">
                          <img
                            className="img-fluid"
                            ref={ref}
                            src={require("../../../assets/img/portfolio/memtech_page_contact_thumbnail.png")}
                            alt="Memtech strona kontaktu"
                          />
                          <div
                            onClick={open}
                            onKeyPress={open}
                            role="button"
                            tabIndex={0}
                            className="hover memtech-gallery__image--hover"
                          >
                            <img
                              src={require("../../../assets/img/loupe-white.svg")}
                              alt="Memtech screen strony płatność"
                            />
                          </div>
                        </div>
                      )}
                    </Item>
                  </div>
                  <div className="col-4">
                    <Item
                      original={require("../../../assets/img/portfolio/memtech_page_about.png")}
                      thumbnail={require("../../../assets/img/portfolio/memtech_page_about_thumbnail.png")}
                      width="1920"
                      height="1947"
                    >
                      {({ ref, open }) => (
                        <div className="memtech-gallery__image">
                          <img
                            className="img-fluid"
                            ref={ref}
                            src={require("../../../assets/img/portfolio/memtech_page_about_thumbnail.png")}
                            alt="Memtech strona kontaktu"
                          />
                          <div
                            onClick={open}
                            onKeyPress={open}
                            role="button"
                            tabIndex={-1}
                            className="hover memtech-gallery__image--hover"
                          >
                            <img
                              src={require("../../../assets/img/loupe-white.svg")}
                              alt="Memtech screen strony płatność"
                            />
                          </div>
                        </div>
                      )}
                    </Item>
                  </div>

                  <div className="col-4">
                    <Item
                      original={require("../../../assets/img/portfolio/memtech_page_404.png")}
                      thumbnail={require("../../../assets/img/portfolio/memtech_page_404_thumbnail.png")}
                      width="1920"
                      height="1947"
                    >
                      {({ ref, open }) => (
                        <div className="memtech-gallery__image">
                          <img
                            className="img-fluid"
                            ref={ref}
                            src={require("../../../assets/img/portfolio/memtech_page_404_thumbnail.png")}
                            alt="Memtech strona kontaktu"
                          />
                          <div
                            onClick={open}
                            onKeyPress={open}
                            role="button"
                            tabIndex={-1}
                            className="hover memtech-gallery__image--hover"
                          >
                            <img
                              src={require("../../../assets/img/loupe-white.svg")}
                              alt="Memtech screen strony płatność"
                            />
                          </div>
                        </div>
                      )}
                    </Item>
                  </div>
                </div>
              </CustomGallery>
            </div>

            <div className="memtech-gallery--mobile d-lg-none">
              <Slider arrows={false}>
                <img
                  className="img-fluid"
                  src={require("../../../assets/img/portfolio/memtech_page_contact_thumbnail.png")}
                  alt="Memtech strona kontaktu"
                />

                <img
                  className="img-fluid"
                  src={require("../../../assets/img/portfolio/memtech_page_about_thumbnail.png")}
                  alt="Memtech strona kontaktu"
                />

                <img
                  className="img-fluid"
                  src={require("../../../assets/img/portfolio/memtech_page_404_thumbnail.png")}
                  alt="Memtech strona kontaktu"
                />
              </Slider>
            </div>

            <DefaultLayout
              shareButton={false}
              fullscreenButton={true}
              zoomButton={false}
              ref={layoutGalleryRef}
            />
          </div>
        </section> */}

        <section className="memtech-section memtech-section__email-footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-6">
                <h2>E-mail signatures</h2>
                <p>
                  We have designed e-mail footers in line with the new brand
                  identification.
                </p>
              </div>
              <div className="col-12 col-md-6">
                <img
                  src={require("../../../assets/img/portfolio/memtech_footer.jpg")}
                  alt="Memtech wizytówka stopka"
                />
              </div>
            </div>
          </div>
        </section>
      </div>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default Memtech;
